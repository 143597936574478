import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StyledEngineProvider } from '@mui/material/styles';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';

import './index.scss';
import './style.scss';
import App from './App';
import { setupStore } from 'shared/store/store';
import environment from 'shared/assets/config';
import { ga4 } from 'shared/libs/gtm';
import config from 'shared/assets/config';

const theme = extendTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 760,
      lg: 1200,
      xl: 1536,
    },
  },
  cssVarPrefix: 'ac',
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '0 !important',
          borderColor: '#f7f9fc',
          '&:hover': {
            border: '#f7f9fc !important',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&:hover': {
            backgroundColor: '#382ebe !important',
            color: '#ffffff',
          },
        },
        inputRoot: {
          '&:hover': {
            borderColor: '2px red solid',
          },
        },
      },
    },
  },
});

// Load the Google analytic
ga4.initialize({ measurementId: config.measurementId });

// Load the hotjar
Hotjar.init(Number(config.hotjarSiteID), 6);

// Load the Sentry for App health monitoring
if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://f825930608ebc046f99b95ef0859adb9@o4507503690448896.ingest.us.sentry.io/4507508584546304',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/quote\.auditcover\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// Load the bugherd
if (process.env.REACT_APP_ENV === 'stage' || process.env.REACT_APP_ENV === 'qa') {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://www.bugherd.com/sidebarv2.js?apikey=qugkxkdvyyg2i8taiqxwhq';

  const scriptPlaceholder = document.getElementById('bugherd-script');
  if (scriptPlaceholder) {
    scriptPlaceholder.appendChild(script);
  } else {
    console.error('Element with ID "bugherd-script" not found.');
  }
}

const queryClient = new QueryClient();
const store = setupStore();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={environment.siteKey}
      useRecaptchaNet={true}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <CssVarsProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BrowserRouter>
                <Provider store={store}>
                  <App />
                </Provider>
              </BrowserRouter>
            </LocalizationProvider>
          </CssVarsProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);
